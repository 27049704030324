<template>
  <div style="width:auto; height:900px;" v-html="poHtmlCode"/>
</template>

<script>
const axios = require('axios');
export default {
  name: 'PDF',
  data() {
    return {
      message: ' ',
      poHtmlCode: '',

    }
  },
  created: async function () {
    const obj = {
      id: this.$route.query.id,
    };
    axios({
      url: `${this.baseUrl}/xinyuan/filePdf/stamp`,
      method: "post",
      data: obj,
    })
        .then((res) => {
          this.poHtmlCode = res.data.msg;
        })
        .catch(function (err) {
          console.log(err)
        });
  },
  methods: {
    //控件中的一些常用方法都在这里调用，比如保存，打印等等
    //保存
    Save() {
      pdfctrl.WebSave();
    },
    SetBookmarks() {
      pdfctrl.BookmarksVisible = !pdfctrl.BookmarksVisible;
    },
    PrintFile() {
      pdfctrl.ShowDialog(4);
    },
    SwitchFullScreen() {
      pdfctrl.FullScreen = !pdfctrl.FullScreen;
    },
    SetPageReal() {
      pdfctrl.SetPageFit(1);
    },
    SetPageFit() {
      pdfctrl.SetPageFit(2);
    },
    SetPageWidth() {
      pdfctrl.SetPageFit(3);
    },
    ZoomIn() {
      pdfctrl.ZoomIn();
    },
    ZoomOut() {
      pdfctrl.ZoomOut();
    },
    FirstPage() {
      pdfctrl.GoToFirstPage();
    },
    PreviousPage() {
      pdfctrl.GoToPreviousPage();
    },
    NextPage() {
      pdfctrl.GoToNextPage();
    },
    LastPage() {
      pdfctrl.GoToLastPage();
    },
    SetRotateRight() {
      pdfctrl.RotateRight();
    },
    SetRotateLeft() {
      pdfctrl.RotateLeft();
    },
    InsertSeal() {
      pdfctrl.zoomseal.AddSeal("王鑫玉", false);
    },
    OnPDFCtrlInit() {
      //PDF的初始化事件回调函数，您可以在这里添加自定义按钮
      if (("linux") != (pdfctrl.ClientOS)) {
        pdfctrl.AddCustomToolButton("隐藏/显示书签", "SetBookmarks()", 0);
      }
      pdfctrl.AddCustomToolButton("保存", "Save()", 1);
      pdfctrl.AddCustomToolButton("打印", "PrintFile()", 6);
      pdfctrl.AddCustomToolButton("-", "", 0);
      pdfctrl.AddCustomToolButton("实际大小", "SetPageReal()", 16);
      pdfctrl.AddCustomToolButton("适合页面", "SetPageFit()", 17);
      pdfctrl.AddCustomToolButton("适合宽度", "SetPageWidth()", 18);
      pdfctrl.AddCustomToolButton("缩小", "ZoomOut()", 17);
      pdfctrl.AddCustomToolButton("放大", "ZoomIn()", 18);
      pdfctrl.AddCustomToolButton("-", "", 0);
      pdfctrl.AddCustomToolButton("首页", "FirstPage()", 8);
      pdfctrl.AddCustomToolButton("上一页", "PreviousPage()", 9);
      pdfctrl.AddCustomToolButton("下一页", "NextPage()", 10);
      pdfctrl.AddCustomToolButton("尾页", "LastPage()", 11);
      pdfctrl.AddCustomToolButton("-", "", 0);
      pdfctrl.AddCustomToolButton("向左旋转90度", "SetRotateLeft()", 12);
      pdfctrl.AddCustomToolButton("向右旋转90度", "SetRotateRight()", 13);
      pdfctrl.AddCustomToolButton("-", "", 0);
      pdfctrl.AddCustomToolButton("全屏/还原", "SwitchFullScreen()", 4)
      pdfctrl.AddCustomToolButton("加盖印章", "InsertSeal()", 2)
    },
  },
  mounted: function () {
    // 将vue中的方法赋值给window
    window.Save = this.Save;
    window.OnPDFCtrlInit = this.OnPDFCtrlInit;
    window.SetBookmarks = this.SetBookmarks;
    window.PrintFile = this.PrintFile;
    window.SwitchFullScreen = this.SwitchFullScreen;
    window.SetPageReal = this.SetPageReal;
    window.SetPageFit = this.SetPageFit;
    window.SetPageWidth = this.SetPageWidth;
    window.ZoomIn = this.ZoomIn;
    window.ZoomOut = this.ZoomOut;
    window.FirstPage = this.FirstPage;
    window.PreviousPage = this.PreviousPage;
    window.NextPage = this.NextPage;
    window.LastPage = this.LastPage;
    window.SetRotateRight = this.SetRotateRight;
    window.SetRotateLeft = this.SetRotateLeft;
    window.InsertSeal = this.InsertSeal;
    window.AfterDocumentOpened = () => {
      pdfctrl.FullScreen = true;
    }
  }
}
</script>
